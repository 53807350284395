.doctor-calendar-container {
    font-family: inherit;
    padding: 15px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);

    .doctor-calendar-months {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .calendar-month {
        width: 48%;
    }

    .calendar-month-title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
    }

    .calendar-month-weekdays {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .weekday {
        width: 14.28%;
        text-align: center;
        font-size: 12px;
        color: #666;
    }

    .calendar-month-days {
        border-top: 1px solid #eee;
    }

    .calendar-week {
        display: flex;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
    }

    .calendar-day {
        width: 14.28%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        border-right: 1px solid #eee;
        font-size: 14px;
    }

    .calendar-day.other-month {
        color: #ccc;
    }

    .calendar-day.today {
        background-color: #f0f8ff;
        font-weight: 600;
    }

    .calendar-day.available {
        background-color: #e3f6ff;
        cursor: pointer;
    }
    
    .calendar-day.absent {
        color: #999;
        background-color: #ebebeb;
    }

    .calendar-day.booked_by_patient{
        background-color: #e7ffbf;
        color: #3d3d3d;
    }
    .calendar-day.selected {
        background-color: #3ca0d6;
        color: white;
    }
    .calendar-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        background: #f9f9f9;
        border-radius: 4px;
    }

    .day-time-slots {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    }

    .day-date {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .day-date-time {
        background-color: #e3f6ff;
        padding: 4px 10px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
    }

    .slots-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .schedule-wrapper .schedule, .schedule-list {
        padding: 10px 0;
        border-top: 1px solid #eee;
    }
    .no-slots-message {
        color: #666;
        font-style: italic;
        padding: 10px 0;
        border-top: 1px solid #eee;
    }

    .schedule-info {
        display: flex;
        align-items: center;
        margin-top: 15px;
        font-size: 14px;
        color: #666;
    }

    .schedule-info__icon {
        margin-right: 8px;
        color: #3ca0d6;
    }      

}

